export const CONNECTION_GAMES = [
    [
        {
            category: "Types of Legislation",
            words: ["Code", "Bill", "Edict", "Act"],
            difficulty: 1,
        },
        {
            category: "Microsoft Programs",
            words: ["Excel", "Word", "Office", "Paint"],
            difficulty: 2,
        },
        {
            category: "School Supply Minus “er”",
            words: ["Bind", "Rule", "Fold", "Sharpen"],
            difficulty: 3,
        },
        {
            category: "____ology Subjects",
            words: ["Bi", "Psych", "Biotech", "Epidemi"],
            difficulty: 4,
        },
    ],
];
